import Menu from './modules/Menu'
import ClothMesh from './modules/ClothMesh'
import barba from '@barba/core';
import gsap from 'gsap';

export default {
  init() {
    // JavaScript to be fired on all pages
    const loadingRemove = () => {
      const canvas = document.querySelector('canvas')
      gsap.to(canvas, {
        opacity: 1,
        duration: 1,
      })
      setTimeout(() => {
        document.querySelector('#loading').classList.add('fade-out')
        setTimeout(() => {
          document.querySelector('#loading').style.display = 'none'
        }, 1000);
        
      }, 1500);
      Menu(false)
    }
    console.log('common test');
    Menu(false)
    ClothMesh()
    barba.init({
      debug: true,
      transitions: [{
        name: 'opacity-transition',
        leave(data) {
          // ClothMesh()
          // setTimeout(() => {
            // const loading = document.querySelector('#loading')
            // const logoHome = document.querySelector('.logo-home')
            // if (loading && logoHome) {
            //   loading.classList.add('fade-out')
            //   logoHome.classList.add('fade-in')
            //   setTimeout(() => {
            //     loading.style.display = 'none'
            //   }, 1000);
            // }
            // Menu(true)
            
          // }, 250);
          
          const canvas = document.querySelector('canvas')
          if(canvas){
            canvas.style.opacity = 0
          }

          gsap.to(data.current.container, {
            opacity: 0, 
            duration: 0.3,
            ease: "power1.in"
          });
        },
        enter(data) {
          // ClothMesh()
          const loading = document.querySelector('#loading')
          // const logoHome = document.querySelector('.logo-home')
          const canvas = document.querySelector('canvas')
        
          // document.querySelector('#rock-media').classList.add('is-menu')
          const normalAnimation = () => {
            if(canvas){
              canvas.style.opacity = 0
            }
            if(loading){
              loading.style.display = 'none'
            }
            // if(logoHome){
            //   logoHome.style.opacity = 1
            // }
  
            gsap.from(data.next.container, {
              opacity: 0,
              duration: 0.3,
              ease: "power1.in",
              onComplete: () => {
                // if(canvas){
                //   canvas.style.opacity = 1
                // }
              }
            })
          }
          

          const backFromDetail = () => {
            loading.style.display = 'none'
            // logoHome.style.opacity = 0
            // document.querySelector('#rock-media').classList.add('is-menu')
            // if(canvas){
            //   canvas.style.opacity = 1
            // }
            
            // logoHome.style.opacity = 1

            gsap.to(canvas, {
              opacity: 1,
              duration: 1,
              // onComplete: () => {
              //   // canvas.style.opacity = 1
              //   logoHome.style.opacity = 1
              // }
            })
          }
          if (data.trigger.classList) {
            if (data.trigger.classList.contains('return')) {
              backFromDetail()
            } else {
              normalAnimation()
            }
          } else {
            loadingRemove()
          }

          
        }
      }]
    });
    barba.hooks.after((data) => {
      console.log(data);
      if (data.trigger.classList) {
        if (data.trigger.classList.contains('open')) {
          // document.querySelector('#rock-media').classList.add('is-menu')
          Menu(true)
          document.querySelectorAll('#rock-media')[1].style.display = 'block'
        } else {
          Menu(false)
          // ClothMesh() 
        }
      } else {
        loadingRemove()
      }
      
    });
  },
  finalize() {
    console.log('common test');
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
