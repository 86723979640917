import gsap from 'gsap';

export default function Menu(isOpen) {

  let menu = isOpen
  let menuLayer = document.querySelectorAll('.rock-media__layout')[1]
  let openMenu = document.querySelectorAll('.rock-media__menu button')[0]
  let closeMenu = document.querySelectorAll('.rock-media__menu button')[1]
  let logos = document.querySelectorAll('.rock-media__logo p')
  const action = [openMenu, closeMenu]

  logos.forEach(logo => {
    if (logo.classList.contains('open-menu')) {
      console.log("ok")
      action.push(logo)
    
    }
  })
  

  action.forEach(element => {
    if(element){
      element.addEventListener('click', (e) => {
        if(element.classList.contains('back-home')){
          e.preventDefault()
        }
        // e.preventDefault()
        // document.querySelectorAll('#rock-media')[1].classList.toggle('is-menu')
        // document.querySelectorAll('#rock-media')[1].style.display = menu ? "none" : "block"
        if(element.classList.contains('close-menu')){
          menu = true
        }
        if(menu){
          const rockContent = document.querySelectorAll('#rock-media')[1]
            gsap.to(menuLayer, {
              opacity: 0,
              duration: 0.3,
              ease: "power1.out",
              onComplete: () => {
                if (rockContent) {
                  rockContent.style.display = "none" 
                }
                
              }
            })
            const canvas = document.querySelector('canvas')
            if (canvas) {
              gsap.to(canvas.style, {
                opacity: 1,
                duration: 0.3,
                ease: "power1.out"
              })
            }
            
          } else {
            document.querySelectorAll('#rock-media')[1].style.display = "block"
            action.forEach(element => {
                 element.style.pointerEvents = 'none'
            })
            gsap.fromTo(menuLayer, 
            { 
              opacity: 0,
            },
            {
              opacity: 1,
              duration: 0.3,
              ease: "power1.out",
              onComplete: () => {
                action.forEach(element => {
                    element.style.pointerEvents = 'auto'
                })
              }
            })
          }
        menu = !menu
      })
    }
  })
}