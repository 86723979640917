import ClothMesh from './modules/ClothMesh'

export default {
  init() {
    // ClothMesh()
    // console.log('home');
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
