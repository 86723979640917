import * as PIXI from 'pixi.js';
import * as dat from 'dat.gui';

export default function ClothMesh() {
    const params = new URLSearchParams(window.location.search)
    const interaction = params.has('controls') ? params.get('controls') : ''
    function randint(min, max) {
        return Math.floor(Math.random() * max) + min;
      }
      
      function isMobile() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
          return true
        }
        return false
      }
      
      var simulationCounter = 0
      var simulationWait = 5
      
      function checkLastClick() {
        // if (isMobile()) {
          if (window.location.pathname !== "/") {
            return
          }
          
          if (simulationCounter > simulationWait) {
            // console.log("click");
            fakePointerDown()
            setTimeout(() => {
              pointerUp()
            }, 500);
          simulationCounter = 0
          } else {
            simulationCounter++
          }
        // }
        
        
    /*    document.querySelector('canvas').addEventListener('mousedown', (e) => {
          e.preventDefault()
          e.stopPropagation()
          simulationCounter = 0
        })
        document.querySelector('canvas').addEventListener('mouseup', (e) => {
          e.preventDefault()
          e.stopPropagation()
          simulationCounter = 0
        })*/
      }

      setInterval(checkLastClick, 100) //review time frequency

      
      var maxwt = 500;
      
      var mesh;
      var mesh2;
      var mesh3;
      var mesh4;
      var mesh5;
      
      var cloth;
      var cloth2;
      var cloth3;
      var cloth4;
      var cloth5;
      
      var spacingX = 5;
      var spacingY = 5;
      var accuracy = 2;
      var wind = 100;
      var cWind = 0;
      var yMultiplier = 2.5
      var xMultiplier = 4.125 // review for snapping points

      if (isMobile()) {
        // console.log("in");
        xMultiplier = 12.5
        yMultiplier = 2.5
      }
      // console.log(xMultiplier, yMultiplier);
      var start = 200;
      var increase = Math.PI * 2 / 100;
      const checkMenu = document.querySelector('#rock-media')
      
      var opts = {
        bloomScale: 1,
        brightness: 1,
        blur: 8,
        quality: 4,
        image: document.getElementById('img1').value,
        image2: document.getElementById('img2').value,
        image3: document.getElementById('img1').value,
        image4: document.getElementById('img3').value,
        gravity: 20,
        friction: .99,
        bounce: 2,
        pointsX: isMobile() ? 50 : 70,
        pointsY: isMobile() ? 30 : 70,
        renderCloth: !true,
        mouseInfluence: 20,
        pinCorners: true,
      };
      
      function sine(){
          opts.gravity = 25 * Math.sin( start ) + 0;
          start += 100;
          
      };
      
      
      
     
      
      
      if(!interaction)
      window.setInterval (sine, 1000);
      
      
    
      
      
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      document.body.appendChild(canvas);
      
      // ctx.strokeStyle = '#111';
      
      var sizeX = canvas.width;
      var sizeY = canvas.height;
      
      var mouse = {
      
        down: false,
        x: 0,
        y: 0,
        px: 0,
        py: 1
      };
      
      /*////////////////////////////////////////*/
      
      var stage = new PIXI.Container();
      var renderer = PIXI.autoDetectRenderer(window.innerWidth, window.innerHeight, { transparent: true });
      // stage.scale.x = 1.1;
      // stage.scale.y = 1.1;
      // stage.pivot.x = stage.width / 2;
      // stage.pivot.y = stage.height / 2;
      document.body.insertBefore(renderer.view, canvas);
      renderer.render(stage);
      
      canvas.width = renderer.width;
      canvas.height = renderer.height;
      
      //This refreshes the renderer on window scaling.
      //HOWEVER this does not refresh the mesh scale.
      
      /*////////////////////////////////////////*/
      
      function loadTexture() {
      
          console.log('loading texture', opts.image);
      
          document.body.className = '';
      
          var texture = new PIXI.Texture.fromImage(opts.image);
          // texture.rotate = 2
          var texture2 = new PIXI.Texture.fromImage(opts.image2);
          // texture2.rotate = 8
          var texture3 = new PIXI.Texture.fromImage(opts.image3);
          // texture3.rotate = 8
          var texture4 = new PIXI.Texture.fromImage(opts.image4);
          // texture4.rotate = 12
      
          if (!texture.requiresUpdate) {
              texture.update();
          }
      
          texture.on('error', function () {
              console.error('AGH!');
          });
      
          texture.on('update', function () {
              document.body.className = '';
      
              console.log('texture loaded');
      
            if (mesh) {
                stage.removeChild(mesh);
              }
              
              mesh = new PIXI.mesh.Plane(this, opts.pointsX, opts.pointsY);
              if (mesh) {
                mesh.width = this.width;
                mesh.height = this.height;
              
              
      
                spacingX = (xMultiplier* renderer.width)/(4*(opts.pointsX - 1));
                spacingY = (yMultiplier *renderer.height) / (opts.pointsY - 1);
        }
      
              cloth = new Cloth(opts.pointsX - 1, opts.pointsY - 1, !opts.pinCorners, isMobile() ? 'left' : '', window.innerWidth / 2 - (opts.pointsX - 1) * spacingX / 2, window.innerHeight * -0.01, mesh);
              // mesh.scale.y *= -1;
              // if (isMobile()) {
                stage.addChild(mesh);
              // }
              
            });
      
      //Add Texture 2
          texture2.on('update', function () {
              document.body.className = '';
      
              console.log('texture2 loaded');
      
              if (mesh2) {
                stage.removeChild(mesh2);
              }
      
              mesh2 = new PIXI.mesh.Plane(this, opts.pointsX, opts.pointsY);
              if (mesh2) {
                mesh2.width = this.width;
                mesh2.height = this.height;
              
              
      
                spacingX = ((isMobile() ? xMultiplier : 4.01)* renderer.width)/(4*(opts.pointsX - 1));
                spacingY = (yMultiplier *renderer.height) / (opts.pointsY - 1);
            }
      
            cloth2 = new Cloth(opts.pointsX - 1, opts.pointsY - 1, !opts.pinCorners, isMobile() ? 'right' : '', window.innerWidth / 2 - (opts.pointsX - 1) * spacingX / 2, isMobile() ?  window.innerHeight * 0.775 : window.innerHeight * 0.475, mesh2);
      
              // if (isMobile()) {
                stage.addChild(mesh2);
              // }
            });
      
      ///Adding in Texture 3 as an incorporation test
            texture3.on('update', function () {
                document.body.className = '';
      
                console.log('texture3 loaded');
      
              if (mesh3) {
                  stage.removeChild(mesh3);
                }
      
                mesh3 = new PIXI.mesh.Plane(this, opts.pointsX, opts.pointsY);
                if (mesh3) {
                  mesh3.width = this.width;
                  mesh3.height = this.height;
                
                
                
      
            //This controls the size of the mesh! It completely ignores the var above, so simply modify this.
            //We want to keep the images proportional and scaling to the width of the canvas. 'spacingY' will not be modified.
            //
            spacingX = (5.5* renderer.width)/(4*(opts.pointsX - 1));
      spacingY = (2.5 *renderer.height) / (opts.pointsY - 1);
          }
            cloth3 = new Cloth(opts.pointsX - 1, opts.pointsY - 1, !opts.pinCorners, 'right', window.innerWidth, window.innerHeight, mesh3);
      
            // if (!isMobile()) {
            //   stage.addChild(mesh3);
            // }
              });
      
              ///Adding in Texture 4
              texture4.on('update', function () {
                  document.body.className = '';
                  console.log('texture4 loaded');
                  setTimeout(() => {
                    document.querySelector('#loading').classList.add('fade-out')
                    // document.querySelector('.logo-home').classList.add('fade-in')
                    setTimeout(() => {
                      document.querySelector('#loading').style.display = 'none'
                    }, 1000);
                    
                  }, 1500);
                if (mesh4) {
                    stage.removeChild(mesh4);
                  }
                  mesh4 = new PIXI.mesh.Plane(this, opts.pointsX, opts.pointsY);
                  if (mesh4) {
                    mesh4.width = this.width;
                    mesh4.height = this.height;
                  
                 
                    spacingX = (xMultiplier * renderer.width)/(4*(opts.pointsX - 1));
                    spacingY = (yMultiplier * renderer.height) / (opts.pointsY - 1);
            }
              cloth4 = new Cloth(opts.pointsX - 1, opts.pointsY - 1, !opts.pinCorners, isMobile() ? 'left' : '', window.innerWidth / 2 - (opts.pointsX - 1) * spacingX / 2, isMobile() ? (window.innerHeight * 1.1 / 4) : window.innerHeight * 1.1, mesh4);
      
              // if (!isMobile()) {
                stage.addChild(mesh4);
              // }
                });
      
      };
      
      loadTexture(opts.image, opts.image2, opts.image3, opts.image4);
      
      (function update() {
        requestAnimationFrame(update);
        ctx.clearRect(0, 0, canvas.width, canvas.height);
          if (cloth) {
            cloth.update(0.016);
          }
          if (cloth2) {
            cloth2.update(0.016);
          }
          if (cloth3) {
            cloth3.update(0.016);
          }
          if (cloth4) {
            cloth4.update(0.016);
          }
          
        renderer.render(stage);
      })(0);
      
      /*////////////////////////////////////////*/
      
      class Point {
        constructor(x, y, flagOrigin) {
          this.flagOrigin = flagOrigin
          switch (this.flagOrigin) {
            case 'left':
              this.x = y
              this.y = x
              this.px = y;
              this.py = x;
              break;
            case 'right':
              this.x = y
              this.y = x
              this.px = y;
              this.py = x;
              break;
          
            default:
              this.x = x
              this.y = y
              this.px = x;
              this.py = y;
              break;
          }
          
          this.vx = 0;
          this.vy = 0;
          this.pinX = null;
          this.pinY = null;
      
          this.constraints = [];
        }
      
        update(delta) {
          if (this.pinX && this.pinY) return this;
      
          if (mouse.down) {
            var dx = this.x - mouse.x;
            var dy = this.y - mouse.y;
            var dist = Math.sqrt(dx * dx + dy * dy);
      
            if (mouse.button === 1 && dist < opts.mouseInfluence) {
              this.px = this.x - (mouse.x - mouse.px);
              this.py = this.y - (mouse.y - mouse.py);
            } else if (dist < mouse.cut) {
              this.constraints = [];
            }
          }
      
          if (wind !== 0) {
            if (5 == randint(0, 50000)) {
              cWind = randint(-100, 100) * Math.sin( start );
              start += 3000;
            }
          }
      
          this.addForce(cWind, opts.gravity, this.flagOrigin);
      
      
      
          var nx = this.x + (this.x - this.px) * opts.friction + this.vx * delta;
          var ny = this.y + (this.y - this.py) * opts.friction + this.vy * delta;
      
          this.px = this.x;
          this.py = this.y;
      
          this.x = nx;
          this.y = ny;
      
          this.vy = this.vx = 0;
      
          if (this.x >= canvas.width) {
            this.px = canvas.width + (canvas.width - this.px) * opts.bounce;
            this.x = canvas.width;
          } else if (this.x <= 0) {
            this.px *= -1 * opts.bounce;
            this.x = 0;
          }
      
          if (this.y >= canvas.height) {
            this.py = canvas.height + (canvas.height - this.py) * opts.bounce;
            this.y = canvas.height;
          } else if (this.y <= 0) {
            this.py *= -1 * opts.bounce;
            this.y = 0;
          }
      
          return this;
        };
      
        draw() {
          var i = this.constraints.length;
          while (i--) {
            this.constraints[i].draw();
          }
        };
      
        resolve() {
          if (this.pinX && this.pinY) {
            this.x = this.pinX;
            this.y = this.pinY;
            return;
          }
      
          this.constraints.forEach(function (constraint) {
            return constraint.resolve();
          });
        };
      
        attach(point) {
          this.constraints.push(new Constraint(this, point));
        };
      
        free(constraint) {
          this.constraints.splice(this.constraints.indexOf(constraint));
        };
      
        addForce(x, y, flagOrigin) {
          switch (flagOrigin) {
            case 'left':
              this.vx += y;
              this.vy += x;
              break;
            case 'right':
              this.vx -= y;
              this.vy += x;
              break;
            case 'down':
              this.vx += x;
              this.vy -= y;
              break;
          
            default:
              this.vx += x;
              this.vy += y;
              break;
          }
          
        };
      
        pin(pinx, piny) {
          this.pinX = pinx;
          this.pinY = piny;
        };
      
        unpin() {
          this.pinX = null;
          this.pinY = null;
        };
      }
      
      /*////////////////////////////////////////*/
      
      class Constraint {
        constructor(p1, p2, length) {
      
          this.p1 = p1;
          this.p2 = p2;
          this.length = length || spacingX;
        }
      
        resolve() {
          var dx = this.p1.x - this.p2.x;
          var dy = this.p1.y - this.p2.y;
          var dist = Math.sqrt(dx * dx + dy * dy);
      
          if (dist < this.length) return;
      
          var diff = (this.length - dist) / dist;
      
          //if (dist > tearDist) this.p1.free(this)
      
          var mul = diff * 0.5 * (1 - this.length / dist);
      
          var px = dx * mul;
          var py = dy * mul;
      
          !this.p1.pinX && (this.p1.x += px);
          !this.p1.pinY && (this.p1.y += py);
          !this.p2.pinX && (this.p2.x -= px);
          !this.p2.pinY && (this.p2.y -= py);
      
          return this;
        };
      
        draw() {
          ctx.moveTo(this.p1.x, this.p1.y);
          ctx.lineTo(this.p2.x, this.p2.y);
        };
      
      }
      
      /*////////////////////////////////////////*/
      
      var count = 0;
      
      class Cloth {
        constructor (clothX, clothY, free, flagOrigin, startX, startY, mesh) {
      
          this.points = [];
      ////////////////////////////////////////////////////////////////////////////////
          //This is where the starting corner is placed. Mess with startY to get the proper placement. Width will have to be variable depending on the image. The multiplier was originally 0.3
          var w = window.innerWidth;
          var h = window.innerHeight;
          this.mesh = mesh
          // var startX = 1;
          // var startY = 1;
      
          for (var y = 0; y <= clothY; y++) {
            for (var x = 0; x <= clothX; x++) {
              var point = new Point(startX + x * spacingX - spacingX * Math.sin(y), 
                y * spacingY + startY + (y !== 0 ? 5 * Math.cos(x) : 0), flagOrigin);
              !free && y === 0 && point.pin(point.x, point.y);
              x !== 0 && point.attach(this.points[this.points.length - 1]);
              y !== 0 && point.attach(this.points[x + (y - 1) * (clothX + 1)]);
      
              this.points.push(point);
            }
          }
        }
      
        update(delta) {
          const that = this
          var i = accuracy;
      
          while (i--) {
            this.points.forEach(function (point) {
              point.resolve();
            });
          }
      
          ctx.beginPath();
      
          this.points.forEach(function (point, i) {
            point.update(delta * delta);
      
            if (opts.renderCloth) {
              point.draw();
            }
      
            if (that.mesh) {
              i *= 2;
              that.mesh.vertices[i] = point.x;
              that.mesh.vertices[i + 1] = point.y;
            }
          });
      
          ctx.stroke();
        }
      }
      
      
      
      function pointerMove(e) {
        simulationCounter = 0
        if (simulationCounter > simulationWait) {
          // console.log("gdhdhdhdhhd");
          return
        }
        
        var pointer = e.touches ? e.touches[0] : e;
        mouse.px = mouse.x || pointer.clientX;
        mouse.py = mouse.y || pointer.clientY;
        mouse.x = pointer.clientX;
        mouse.y = pointer.clientY;

        // console.log(mouse.px);
        // console.log(mouse.py);
        // console.log(mouse.x);
        // console.log(mouse.y);
      }
      
      
      var change = true
      var freqToSplit = 2
      var changeFreq = true
      
      function pointerMoveSimulate() {
        simulationCounter = 0
        // mouse.down = true
        // mouse.button = 1
        // var pointer = e.touches ? e.touches[0] : e;
        // console.log(pointer);
        change = !change
        const box = document.querySelector('canvas').getBoundingClientRect()
        const xCenter = (box.left + box.right) / 2
        const yCenter = (box.top + box.bottom) / 2
        if (change) {
          changeFreq = !changeFreq
        }
        freqToSplit = !changeFreq ? 4 : 2
      /*           mouse.px = Math.random() * randint(0, 20) + xCenter - Math.random() * 2 
        mouse.py = Math.random() * randint(0, 20) + yCenter - Math.random() * 2 
        mouse.x = Math.random() * randint(0, 20) + xCenter - Math.random() * 2
        mouse.y = Math.random() * randint(0, 20) + yCenter - Math.random() * 2 
      */    var lowerMin=-100,upperMax=100;
            var newWidth = change ? window.innerWidth / freqToSplit : window.innerWidth
            var newHeight = change ? window.innerHeight / freqToSplit : window.innerHeight
        mouse.px = newWidth+randint(lowerMin,upperMax);
      
        mouse.py = newHeight+randint(lowerMin,upperMax);
      
        mouse.x = newWidth+randint(lowerMin,upperMax);
      
        mouse.y = newHeight+randint(lowerMin,upperMax);
      
      
        // start += 200
        // mouse.down = false
        // mouse.button = 0
        // console.log(mouse.px);
        // console.log(mouse.py);
        // console.log(mouse.x);
        // console.log(mouse.y);
      }
      
      
      
      function pointerDown(e) {
        simulationCounter = 0
        if (simulationCounter > simulationWait) {
          return
        }
        mouse.down = true;
        mouse.button = 1;
        pointerMove(e);
      }
      function fakePointerDown() {
        mouse.down = true;
        mouse.button = 1;
        pointerMoveSimulate();
      }
      
      
      
      function pointerUp(e) {
        simulationCounter = 0
        if (simulationCounter > simulationWait) {
          return
        }
        mouse.down = false;
        mouse.px = null;
        mouse.py = null;
        // console.log('pointer up');
      }
      
      if (isMobile()) {
        opts.mouseInfluence = 5;
        // opts.friction = 0.7
      }
      
      function animate() {
          requestAnimationFrame(animate);
      
          // render the stage
          renderer.render(stage);
      }
      
      var drawingArea;
      
      window.onresize = function (event){
        var w = window.innerWidth;
        var h = window.innerHeight;
        sizeX = w;
      
         //this part resizes the canvas but keeps ratio the same
      renderer.view.style.width = w + "px";
      renderer.view.style.height = h + "px";
      
      //drawingArea.style.width = w + "px";
      //drawingArea.style.height = h + "px";
      
        //canvas.style.width = w + "px";
      //  canvas.style.height = wh+ "px";
      
         //this part adjusts the ratio:
        renderer.resize(w,h);
       }

       
      
        
      if (interaction === "true" && !isMobile()) 
      {
        document.body.addEventListener('mousedown', pointerDown);
        document.body.addEventListener('touchstart', pointerDown);
        
        document.body.addEventListener('mousemove', pointerMove);
        document.body.addEventListener('touchmove', pointerMove);
        
        document.body.addEventListener('mouseup', pointerUp);
        document.body.addEventListener('touchend', pointerUp);
        document.body.addEventListener('mouseleave', pointerUp);
}
}